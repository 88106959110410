import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div>
            <footer className="text-center text-lg-start text-white" style={{ backgroundColor: "#1c2331" }}>
                <section className="py-2">
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-12 col-md-4 col-lg-3" style={{ textAlign: "left" }}>
                                <h6 className="text-uppercase fw-bold">Quick Links</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", height: "2px", background: "rgb(15 88 136);" }} />
                                <p><Link to="/about" className="text-white">About</Link></p>
                                <p><Link to="/Contact" className="text-white">Contact</Link></p>
                                <p><Link to="/Blog" className="text-white">Blog</Link></p>
                                <p><Link to="/Faq" className="text-white">FAQ</Link></p>
                                <p><Link to="/Privacy" className="text-white">Privacy Policy</Link></p>
                                <p><Link to="/Termscondition" className="text-white">Terms & Conditions</Link></p>
                            </div>

                            <div className="col-12 col-md-4 col-lg-3" style={{ textAlign: "left" }}>
                                <h6 className="text-uppercase fw-bold">Compliance</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "rgb(15 88 136);", height: "2px" }} />
                                <p><a href="#!" className="text-white">MSME Registration</a></p>
                                <p><a href="#!" className="text-white">FSSAI Registration</a></p>
                                <p><a href="#!" className="text-white">Trademark Registration</a></p>
                                <p><a href="#!" className="text-white">ISO certification</a></p>
                                <p><a href="#!" className="text-white">Company law compliance</a></p>
                                <p><a href="#!" className="text-white">LLP law compliance</a></p>
                            </div>

                            <div className="col-12 col-md-4 col-lg-3" style={{ textAlign: "left" }}>
                                <h6 className="text-uppercase fw-bold">Investments</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px", backgroundColor: "rgb(15 88 136);", height: "2px" }} />
                                <p><a href="#!" className="text-white">Mutual fund</a></p>
                                <p><a href="#!" className="text-white">Demat account</a></p>
                                <p><a href="#!" className="text-white">Investment in real estate</a></p>
                                <p><a href="#!" className="text-white">Fixed Deposits</a></p>
                                <p><a href="#!" className="text-white">Bonds</a></p>
                                <p><a href="#!" className="text-white">Life Insurance</a></p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center p-3">
                    © 2023 Copyright: <a className="text-white" href="https://compliancekare.com/">Compliancekare.com</a> Design & Development by <a href="https://ndtechland.com/" target='_blank' style={{ color: "#4CAF50" }}>ND Techland Pvt. Ltd</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
