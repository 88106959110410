import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopHeader from '../Header/TopHeader';
import Navbar from '../Header/Navbar';
import Swal from 'sweetalert2';

const Forgetpassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate API request for password reset
    Swal.fire({
      title: "Success!",
      text: `Password reset link sent to: ${email}`,
      icon: "success",
    });
  };

  return (
    <>
      <TopHeader />
      <Navbar />
      <div className="wrapper">
        <div className="container1 p-4">
          <div className="col-left">
            <div className="login-text">
              <h2>Compliancekare</h2>
              <p>Reset your password to regain access to your account.</p>
              <p>We’ll send you instructions to reset your password.</p>
            </div>
          </div>
          <div className="col-right">
            <div className="login-form">
              <h2>Forgot Password</h2>
              <form onSubmit={handleSubmit}>
                <p>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </p>
                <div>
                  <p>
                    <button className="btn btn-success" type="submit">Send Reset Link</button>
                  </p>
                </div>
                <p>
                  <Link to="/Login"><a href="" style={{color :"black"}}>Back to login?</a></Link>
                  <Link to="/Signup">Create an account.</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpassword;
