import React, { useState } from 'react';
import "../Css/LoginPage.css";
import Swal from 'sweetalert2';

import { Link, useNavigate } from 'react-router-dom';
import TopHeader from '../Header/TopHeader';
import Navbar from '../Header/Navbar';

const Signup = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        mobileNo: '',
        email: '',
        password: '',
        vendorIdOrCoupon: '', // Added new field for vendorIdOrCoupon
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('https://api.compliancekare.com/api/ApiAccount/Registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                Swal.fire({
                    title: "Success!",
                    text: "Registration successful!",
                    icon: "success"
                }).then(() => {
                    setFormData({
                        userName: '',
                        mobileNo: '',
                        email: '',
                        password: '',
                        vendorIdOrCoupon: '', // Reset the new field as well
                    });

                    navigate('/');
                });
            } else {
                if (data && data.errors) {
                    if (data.errors.Email) {
                        Swal.fire({
                            title: "Error!",
                            text: "Email already exists. Please use a different email address.",
                            icon: "error"
                        });
                    } else if (data.errors.MobileNo) {
                        Swal.fire({
                            title: "Error!",
                            text: "Mobile number already exists. Please use a different mobile number.",
                            icon: "error"
                        });
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Registration failed. Please try again.",
                            icon: "error"
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Registration failed. Please try again.",
                        icon: "error"
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: "Error!",
                text: "Something went wrong. Please try again later.",
                icon: "error"
            });
        }
    };

    return (
        <>
            <TopHeader />
            <Navbar />

            <div className="wrapper">
                <div className="container1 p-4">
                    <div className="col-left">
                        <div className="login-text">
                            <h2>Compliancekare</h2>
                            <p >
                                Join our 1M+ customers who eFile with us. Easiest Way of e-filing
                                your Income Tax Return in India <br /> Best income tax efiling
                                website in India
                            </p>
                            <div style={{ display: 'flex' }}>
                                <div>
                                   
                                </div>
                                &nbsp; &nbsp;
                                <div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-right">
                        <div className="login-form">
                            <h2>SignUp</h2>
                            <form onSubmit={handleSubmit}>
                                <p>
                                    <input
                                        type="text"
                                        name="userName"
                                        value={formData.userName}
                                        placeholder="Username"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        type="text"
                                        name="mobileNo"
                                        value={formData.mobileNo}
                                        placeholder="Mobile No"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        placeholder="Email Address"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        placeholder="Password"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        type="text"
                                        name="vendorIdOrCoupon"
                                        value={formData.vendorIdOrCoupon}
                                        placeholder="Vendor ID or Coupon"
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <br /> <br />
                                <div>
                                    <p>
                                        <input className="btn" type="submit" value="Signup" />
                                    </p>
                                </div>
                                <p>
                                    Already have an account?{' '}
                                    <Link to="/login">
                                        Log In
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    );
};

export default Signup;
