import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import socialIcon1 from '../Images/1.png';
import socialIcon2 from '../Images/2.png';
import socialIcon4 from '../Images/4.png';
import socialIcon5 from '../Images/5.png';

function TopHeader() {
    const marqueeRef = useRef(null);

    const handleMouseEnter = () => {
        if (marqueeRef.current) {
            marqueeRef.current.stop();
        }
    };

    const handleMouseLeave = () => {
        if (marqueeRef.current) {
            marqueeRef.current.start();
        }
    };

    return (
        <>
            <nav className="top-fixed " style={{ background: "#105886" }}>
                <div className="d-flex">
                    <marquee
                        behavior="alternate"
                        style={{ color: "#fff", fontFamily: "poppins", marginTop: "16px" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={marqueeRef}
                    >
                        Still You can file Your ITR | Start Your Tax Saving Today:-  <button class="rounded text-white border-0" style={{background:"#105985"}}>Click Here</button>
                    </marquee>
                   
                    <div className="d-flex justify-content-end">
                        <div className="p-2">
                            <Link to="https://www.facebook.com/compliancekare/" target='_blank'>
                                <img src={socialIcon1} alt="Social_icon" style={{ width: "40px", cursor: "pointer" }} title='facebook' />
                            </Link>
                        </div>
                        <div className="p-2">
                            <Link to="https://www.instagram.com/compliancekare/" target='_blank'>
                                <img src={socialIcon5} alt="Social_icon" style={{ width: "40px", cursor: "pointer" }} title='Instagram' />
                            </Link>
                        </div>
                        <div className="p-2">
                            <Link to="https://twitter.com/compliancekare/" target='_blank'>
                                <img src={socialIcon4} alt="Social_icon" style={{ width: "40px", cursor: "pointer" }} title='twitter' />
                            </Link>
                        </div>
                        <div className="p-2">
                            <Link to="" target='_blank'>
                                <img src={socialIcon2} alt="Social_icon" style={{ width: "40px", cursor: "pointer" }} title='youtube' />
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default TopHeader;
