import React from 'react';
import { Link } from 'react-router-dom';
import "../Css/About.css";
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';

function About() {
    return (
        <>
            <Navbar />
            <section className="about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="content-column col-lg-6 col-md-12 order-2 order-lg-1">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="title">Welcome to ComplianceKare</span>
                                    <h2>Who We Are</h2>
                                </div>
                                <div className="text">
                                    ComplianceKare.com is a leading Tax, Account, and Legal service firm with a commitment to delivering expert guidance, personalized and comprehensive financial solutions. With a team of highly skilled and experienced professionals, we pride ourselves on our unwavering dedication to client satisfaction.
                                </div>
                                <div className="text">
                                    ComplianceKare is a comprehensive compliance management solution that focuses on streamlining and enhancing the compliance processes within an organization. This platform is designed to help businesses meet regulatory requirements, manage risks, and ensure adherence to industry standards.
                                </div>
                                <div className="text">
                                    ComplianceKare offers a centralized and automated approach to compliance management, bringing together various facets of regulatory adherence into a cohesive system.
                                </div>
                                <div className="btn-box">
                                    <Link to="/contact" className="theme-btn btn-style-one">Contact Us</Link>
                                </div>
                            </div>
                        </div>

                        {/* Image Column */}
                        <div className="image-column col-lg-6 col-md-12 order-1 order-lg-2">
                            <div className="inner-column wow fadeInLeft">
                                <figure className="image-1">
                                    <a href="#" className="lightbox-image" data-fancybox="images">
                                        <img
                                            title="ComplianceKare"
                                            className="img-fluid"
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREGI3wRc202IccrxujRY5QK23KEeZVxeSAqg&usqp=CAU"
                                            alt="Compliance Kare"
                                        />
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>

                    <div className="sec-title text-center">
                        <span className="title">Our Mission</span>
                        <h3>Pioneering Excellence in Regulatory Compliance &amp; Management</h3>
                    </div>
                    <div className="text text-center">
                        At ComplianceKare.com, our mission is to empower individuals and businesses by providing exceptional Tax, Accounting, and Legal services, tailored to meet the unique challenges of today’s dynamic regulatory environment. We are dedicated to simplifying the complexity of compliance management, ensuring that our clients can focus on their core business operations with peace of mind, knowing that they are in full compliance with ever-evolving laws and regulations.
                    </div>
                    <div className="text text-center">
                        Our platform provides comprehensive solutions that enable businesses to proactively address compliance risks, avoid penalties, and meet industry standards efficiently. From automated audit trails to real-time reporting, we strive to provide the tools and insights necessary for organizations to achieve complete transparency in their compliance processes.
                    </div>
                    <div className="text text-center">
                        We believe in building long-term partnerships with our clients, delivering personalized services that not only meet their immediate needs but also contribute to sustainable growth. Our commitment to innovation and excellence ensures that ComplianceKare remains at the forefront of the compliance industry, driving success for our clients through reliable, forward-thinking solutions.
                    </div>
                    <div className="text text-center">
                        At ComplianceKare.com, we are not just a service provider; we are your trusted partner in navigating the complexities of regulatory compliance, offering expertise, integrity, and a client-centric approach to every challenge.
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default About;
