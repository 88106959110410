import React from 'react'
import Map3 from '../Images/CCA-Abstract-Shapes-08.jpg'
import './MapBanner.css'
function MapBanner() {

    
    return (
        <>


            <div>


                <div className="row justify-content-center">
                    <h3 className='text-center Text_Heading1 ' >Effortless E-filing of Income Tax Returns in India</h3>
                    <div className="col-lg-5 ">
                        <div className="row">
                            <div className="col-12">
                                <div className="info-box text_alignment">

                                    <div className="ms-4">
                                        <h3>Best income tax e-filing website in India</h3>
                                        <ul>
                                            <p >Filing your income tax return electronically has become increasingly streamlined in India, with user-friendly platforms designed to simplify the process. Here's a concise guide outlining the easiest way to e-file your income tax return:</p>

                                            <li className='Text_fontt'> <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />Online Platforms</li> <br />
                                            <li className='Text_fontt'><img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />Preparation of Documents</li><br />
                                            <li className='Text_fontt'><img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />User-Friendly Interfaces</li><br />
                                            <li className='Text_fontt'><img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />Data Import and Prefill Features</li><br />
                                            <li className='Text_fontt'><img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />Prompt Assistance and Support</li><br />
                                            <li className='Text_fontt'><img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />Regular Updates and Compliance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 mt-4">
            
                            </div> */}

                        </div>
                    </div>
                    <div className="col-lg-5">
                        <img src={Map3} alt="poster" className='Poster_image' />
                    </div>
                </div>

            </div>




           


        </>
    )
}

export default MapBanner