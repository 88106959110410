import React from 'react';

function PopularGuide() {
    return (
        <div className="container py-4">
            <h1 className="Text_Heading text-center mb-4">Our Services</h1>

            <div className="row">
                <div className="col-md-6">
                    <h2 className="text-capitalize font-weight-bold my-3">
                        Our Legal Compliance <span style={{ color: "#105784" }}>Services</span>
                    </h2>
                    <p className="text-black" style={{ lineHeight: 1.6, textAlign: "justify" }}>
                        Our legal compliance services offer comprehensive solutions to ensure that businesses operate in accordance with applicable laws and regulations. We specialize in guiding organizations through the complex landscape of legal requirements, helping them navigate diverse areas such as labor laws, environmental regulations, data protection, and corporate governance.
                    </p>
                    <p className="text-black" style={{ lineHeight: 1.6, textAlign: "justify" }}>
                        Our team of experienced legal professionals conducts thorough assessments of existing processes, identifies potential compliance gaps, and implements robust frameworks to address legal obligations effectively. We tailor our services to meet the specific needs of each client, providing proactive advice to mitigate risks and avoid legal pitfalls.
                    </p>
                    <p className="text-black" style={{ lineHeight: 1.6, textAlign: "justify" }}>
                        From drafting policies and procedures to conducting compliance audits, our services are designed to enhance transparency, mitigate legal risks, and foster a culture of lawful operations within the business environment. By engaging our legal compliance services, businesses can fortify their legal standing, demonstrate ethical governance, and ensure sustained success in today's regulatory landscape.
                    </p>
                </div>

                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-capitalize font-weight-bold my-3">
                                Explore Our Key Services
                            </h4>
                        </div>

                        {[
                            {
                                title: "Regulatory Compliance",
                                description: "We assist businesses in understanding and adhering to industry-specific regulations, ensuring that operations align with legal requirements."
                            },
                            {
                                title: "Corporate Governance",
                                description: "Strengthen your corporate structure with our expertise in establishing and maintaining effective governance practices."
                            },
                            {
                                title: "Employment Law Compliance",
                                description: "Navigate the complexities of employment regulations, from drafting policies to handling disputes, with our comprehensive employment law compliance services."
                            },
                            {
                                title: "How to E-verify Income Tax Return ",
                                description: "To electronically verify your Income Tax Return (ITR) for the Financial Year 2023-24, you can use various methods approved by the Income Tax Department of India."
                            }
                        ].map((service, index) => (
                            <div className="col-md-6 px-4 my-2" key={index}>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <h5 className="mb-2" style={{ fontWeight: 700 }}>
                                            <a href="#!" style={{ color: "#105784" }}>
                                                {service.title}
                                            </a>
                                        </h5>
                                        <p className="text-secondary" style={{ textAlign: "justify" }}>
                                            {service.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopularGuide;
