import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Components/Home/Main';
import Login from './Components/Account/Login';
import Signup from './Components/Account/Signup';
import Contact from './Components/Pages/Contact';
import ScrollToTopButton from './Components/Pages/ScrollToTop';
import Review from './Components/Pages/Review';
import Rewards from './Components/Pages/Rewards';
import About from './Components/Screens/About';
import Faq from './Components/Screens/Faq';
import Blog from './Components/Screens/Blog';
import Privacy from './Components/Screens/Privacy';
import Termscondition from './Components/Screens/Termscondition';
import Services from './Components/Pages/AllServices/Services';
import UserDashboard from './Components/Pages/UserDashboard';
import Ourcompliances from './Components/Screens/Ourcompliances';
import Forgetpassword from './Components/Screens/Forgetpassword';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Services/:id" element={<Services />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/review" element={<Review />} />
        <Route path="/reward" element={<Rewards />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Termscondition" element={<Termscondition />} />
        <Route path="/UserDashboard" element={<UserDashboard />} />
        <Route path="/Ourcompliances" element={<Ourcompliances />} />
        <Route path="/Forgetpassword" element={<Forgetpassword />} />
        <Route path="*" element={<p>Page not found</p>} />
      </Routes>
      <ScrollToTopButton />
    </>
  );
}

export default App;
