import React from 'react';
import './TaxServices.css';
import { FaUser, FaBriefcase, FaChartLine, FaShieldAlt } from 'react-icons/fa';

function TaxServices() {
  return (
    <div className="tax-services-container">
      <div className="title text-center">
        <h2>Our Tax Services</h2>
        <p className="subtitle">Professional solutions tailored for you</p>
      </div>

      <div className="services-grid">
        <div className="service-card">
          <div className="card-background individual">
            <div className="icon individual-icon">
              <FaUser size={60} />
            </div>
            <h3 className="service-heading">Individual Tax Services</h3>
            <p className="service-description">
              Optimize your tax positions and ensure compliance with the latest tax laws.
            </p>
          </div>
        </div>

        <div className="service-card">
          <div className="card-background business">
            <div className="icon business-icon">
              <FaBriefcase size={60} />
            </div>
            <h3 className="service-heading">Business Tax Services</h3>
            <p className="service-description">
              Comprehensive solutions to minimize tax liabilities and enhance profitability.
            </p>
          </div>
        </div>

        <div className="service-card">
          <div className="card-background consulting">
            <div className="icon consulting-icon">
              <FaChartLine size={60} />
            </div>
            <h3 className="service-heading">Tax Consulting</h3>
            <p className="service-description">
              Expert advice for navigating complex tax issues with confidence.
            </p>
          </div>
        </div>

        <div className="service-card">
          <div className="card-background representation">
            <div className="icon representation-icon">
              <FaShieldAlt size={60} />
            </div>
            <h3 className="service-heading">Tax Representation</h3>
            <p className="service-description">
              Representation before authorities to protect your interests.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxServices;
