import React from 'react';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';

const Privacy = () => {
    const containerStyle = {
        padding: '40px 20px',
        maxWidth: '1209px',
        margin: '32px auto',
        lineHeight: '1.8',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    };

    const titleStyle = {
        fontSize: '2em',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#2c3e50',
    };

    const sectionTitleStyle = {
        fontSize: '1.5em',
        marginBottom: '15px',
        color: '#34495e',
        borderBottom: '2px solid #ddd',
        paddingBottom: '5px',
    };

    const listStyle = {
        marginLeft: '20px',
        marginBottom: '20px',
    };

    const paragraphStyle = {
        marginBottom: '20px',
        textAlign: 'justify',
    };

    return (
        <>
            <Navbar />
            <div style={containerStyle}>
                <h1 style={titleStyle}>Privacy Policy</h1>
                

                <h2 style={sectionTitleStyle}>Introduction</h2>
                <p style={paragraphStyle}>
                    We at Compliancekare are committed to safeguarding your privacy. This Privacy Policy outlines the practices
                    and policies related to the collection, use, storage, and disclosure of personal information that we receive from
                    users of our services, in accordance with the laws of India, including but not limited to the Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, and other
                    applicable data protection regulations.
                </p>

                <h2 style={sectionTitleStyle}>Collection of Information</h2>
                <p style={paragraphStyle}>We collect and process the following types of personal information:</p>
                <ul style={listStyle}>
                    <li><strong>Personal Information:</strong> Your name, email address, phone number, and any other information provided voluntarily.</li>
                    <li><strong>Sensitive Personal Data or Information:</strong> Passwords, financial information, health information, etc.</li>
                    <li><strong>Usage Data:</strong> Information automatically collected from your device, such as IP address, browser type, and usage patterns.</li>
                </ul>

                <h2 style={sectionTitleStyle}>Use of Collected Information</h2>
                <p style={paragraphStyle}>We use the information we collect for the following purposes:</p>
                <ul style={listStyle}>
                    <li>To provide and maintain our services.</li>
                    <li>To notify you about changes to our services.</li>
                    <li>To allow you to participate in interactive features of our services.</li>
                    <li>To provide customer support.</li>
                    <li>To gather analysis to improve our services.</li>
                    <li>To monitor service usage.</li>
                    <li>To detect, prevent, and address technical issues.</li>
                    <li>To comply with legal obligations.</li>
                </ul>

                <h2 style={sectionTitleStyle}>Disclosure of Information</h2>
                <p style={paragraphStyle}>We may disclose your personal information in the following circumstances:</p>
                <ul style={listStyle}>
                    <li>When required by law or in response to legal processes.</li>
                    <li>To law enforcement authorities or regulatory bodies.</li>
                    <li>To protect our rights, privacy, or property, and that of others.</li>
                </ul>

                <h2 style={sectionTitleStyle}>Data Security</h2>
                <p style={paragraphStyle}>
                    We implement reasonable security practices to protect your personal information, but no method is 100% secure.
                </p>

                <h2 style={sectionTitleStyle}>Your Rights</h2>
                <p style={paragraphStyle}>
                    Under Indian law, you have the right to access and correct your personal information. Contact us at info@compliancekare.com or +91 9625758585.
                </p>

                <h2 style={sectionTitleStyle}>Data Retention</h2>
                <p style={paragraphStyle}>
                    We retain your personal information as long as necessary to fulfill the purposes for which it was collected or required by law.
                </p>

                <h2 style={sectionTitleStyle}>Changes to This Privacy Policy</h2>
                <p style={paragraphStyle}>
                    We may update this Privacy Policy and will notify you by posting the new version on this page.
                </p>

                <h2 style={sectionTitleStyle}>Contact Us</h2>
                <p style={paragraphStyle}>
                    If you have any questions, contact us at info@compliancekare.com or +91 9625758585.
                </p>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;
